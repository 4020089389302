import { useDeviceQuery } from "@/utils";
import {
  Card,
  Flex,
  useCombobox,
  Button,
  rem,
  MantineProvider,
  Box,
  Text,
  Anchor,
  Modal,
  Select,
  Radio,
  Group,
} from "@mantine/core";
import { DateValue, DatesRangeValue } from "@mantine/dates";
import {
  Dispatch,
  RefObject,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { Trans, useTranslation } from "next-i18next";
import {
  IconArrowsExchange,
  IconChevronDown,
  IconExternalLink,
} from "@tabler/icons-react";
import { searchTheme } from "./theme";
import dayjs from "../../utils/dayjs";
import { useDisclosure, useEventListener } from "@mantine/hooks";
import { TicketAgeClassification } from "../TicketAgeClassification";
import Link from "next/link";
import { LocationField } from "./LocationField";
import { SEARCH_DEVICE, SEARCH_TYPE } from "@/constants";
import { CTAButton } from "../CTAButton";
import { useRouter } from "next/router";
import { IParamError, IRoute } from "@/types";
import { useForm } from "@mantine/form";
import { PopoverAlert } from "../PopoverAlert";
import { POPULAR_ROUTES } from "@/constants/routes";
import { DatePickerInput } from "./DatePickerInput";

export function Search({
  setSearchRef,
  area,
}: {
  setSearchRef: Dispatch<SetStateAction<RefObject<HTMLDivElement> | null>>;
  area?: string;
}): JSX.Element {
  const { t, i18n } = useTranslation();
  const [isMobile] = useDeviceQuery();
  const router = useRouter();
  const { query } = router;
  const [cid, setCid] = useState<string | string[]>("");
  const [referral, setReferral] = useState<string | string[]>("");
  const [departureError, setDepartureError] = useState<IParamError>();
  const [destinationError, setDestinationError] = useState<IParamError>();

  useEffect(() => {
    if (!query.cid) {
      setCid(isMobile ? SEARCH_DEVICE.SP : SEARCH_DEVICE.PC);
    } else {
      setCid(query.cid);
    }

    if (query.via) {
      setReferral(query.via);
    }
  }, [isMobile, query.cid, query.via]);

  const timeCombobox = useCombobox({
    onDropdownClose: () => timeCombobox.resetSelectedOption(),
  });
  const [dateValue, setDateValue] = useState<DateValue>(
    dayjs(new Date()).toDate()
  );
  const [timeOptions, setTimeOptions] = useState<string[]>(
    Array.from({ length: 20 }, (_, index) => `${index + 4}:00`)
  );
  const [dateRangeValue, setDateRangeValue] = useState<DatesRangeValue>([
    dayjs(new Date()).toDate(),
    dayjs(new Date()).add(1, "day").toDate(),
  ]);
  const [opened, { open, close }] = useDisclosure(false);
  const cardRef = useRef<HTMLDivElement>(null);
  const [routeData, setRouteData] = useState<IRoute | undefined>();
  const toggleDepartureError = useCallback(() => {
    setDepartureError((data) => ({ ...data, isError: false }));
  }, []);
  const [earliestDate, setEarliestDate] = useState<DateValue | null>(null);
  const toggleDestinationError = useCallback(() => {
    setDestinationError((data) => ({ ...data, isError: false }));
  }, []);
  const departureClickListener = useEventListener(
    "click",
    toggleDepartureError
  );
  const destinationClickListener = useEventListener(
    "click",
    toggleDestinationError
  );
  const popularRoutes = area ? POPULAR_ROUTES[area] : POPULAR_ROUTES.default;

  const form = useForm({
    initialValues: {
      depStationName: "",
      depStationCode: "",
      arvStationName: "",
      arvStationCode: "",
      via: "",
      date: "",
      time: "9:00",
      adult: "1",
      child: "0",
      searchType: SEARCH_TYPE.ONE_WAY,
      returnDate: "",
    },

    transformValues: (values) => ({
      cid: cid,
      via: referral,
      depStationName: routeData?.from?.name,
      departure: routeData?.from?.code,
      arvStationName: routeData?.to?.name,
      arrival: routeData?.to?.code,
      date:
        values.searchType === SEARCH_TYPE.ROUND_TRIP
          ? dayjs(dateRangeValue[0]).format("YYYYMMDD")
          : dayjs(dateValue).format("YYYYMMDD"),
      returnDate:
        values.searchType === SEARCH_TYPE.ROUND_TRIP
          ? dayjs(dateRangeValue[1]).format("YYYYMMDD")
          : "",
      time: values?.time?.split(":")[0],
      adult: values.adult,
      child: values.child,
      searchType: values.searchType,
    }),
  });
  const searchParams = form.getTransformedValues();
  const isRoundTrip = searchParams.searchType === SEARCH_TYPE.ROUND_TRIP;

  /**
   * Handle swapping of values for FROM and TO fields on button click.
   */
  const swapDestinationValues = () => {
    setRouteData((prevRouteData) => {
      const newRouteData = {
        from: prevRouteData?.to,
        to: prevRouteData?.from,
      };

      form.setValues({
        depStationName: newRouteData?.from?.name,
        arvStationName: newRouteData?.to?.name,
      });

      return newRouteData;
    });
  };

  useEffect(() => {
    setSearchRef(cardRef);
  }, [setSearchRef, cardRef]);

  useEffect(() => {
    if (searchParams.depStationName) {
      toggleDestinationError();
    }
    if (searchParams.arvStationName) {
      toggleDepartureError();
    }
  }, [
    searchParams.arvStationName,
    searchParams.depStationName,
    toggleDepartureError,
    toggleDestinationError,
  ]);

  const validateStation = (
    station: string,
    setError: (errorData: any) => void,
    formDataName?: string,
    formDataCode?: string
  ) => {
    if (station === "" && !formDataName) {
      setError({
        isError: true,
        errorMessage: t("common:search.field_required_validation"),
      });
      return true;
    } else if (station !== "" && !formDataCode) {
      setError({
        isError: true,
        errorMessage: t("common:search.incorrect_station"),
      });
      return true;
    } else {
      setError({ isError: false, errorMessage: "" });
      return false;
    }
  };

  const handleSubmitData = () => {
    const formData = form.getTransformedValues();

    const departureError = validateStation(
      form.values.depStationName,
      setDepartureError,
      formData.depStationName,
      formData.departure
    );
    const destinationError = validateStation(
      form.values.arvStationName,
      setDestinationError,
      formData.arvStationName,
      formData.arrival
    );

    const stringifiedFormData = Object.fromEntries(
      Object.entries(formData)?.map(([key, value]) => [key, String(value)])
    );
    const params = new URLSearchParams(stringifiedFormData).toString();

    if (!departureError && !destinationError) {
      router.push(
        `${process.env.NEXT_PUBLIC_NAVITIME_SUBMIT_URL}/${i18n.language}/booking/jr/search?${params}`
      );
    }
  };

  /**
   * Handle setting of available options for departure time.
   */
  useEffect(() => {
    const now = new Date();
    const currentDate = dayjs(now).format("YYYY-MM-DD");
    let currentTime = now.getHours();
    const availableTime = currentTime + 3;
    let defaultTime = "9:00";

    if (
      currentDate === dayjs(dateValue).format("YYYY-MM-DD") &&
      currentDate === dayjs(dateRangeValue[0]).format("YYYY-MM-DD")
    ) {
      // If current time is 19:00 onwards of today, set default date and time to next day.
      if (currentTime >= 19) {
        setDateValue(dayjs(new Date()).add(1, "day").toDate());
        setDateRangeValue([
          dayjs(new Date()).add(1, "day").toDate(),
          dayjs(new Date()).add(2, "day").toDate(),
        ]);
        setEarliestDate(dayjs(new Date()).add(1, "day").toDate());
      }
      // If earliest date to book is same as current date, set earliest bookable time to
      // 3 hours after the current hour.
      let updatedList: string[] = timeOptions.filter((hour) => {
        const hourValue = parseInt(hour.split(":")[0], 10);
        return hourValue >= availableTime;
      });
      setTimeOptions(updatedList);

      // If 9:00 no longer exists in the list of available hours, set earliest hour as default.
      defaultTime = updatedList.includes(defaultTime)
        ? defaultTime
        : updatedList[0];
      form.setValues({ time: defaultTime });
    } else {
      // If user selects a later date, return default options to 9:00 to 23:00 and
      // set default selected time to 9:00.
      setTimeOptions(
        Array.from({ length: 20 }, (_, index) => `${index + 4}:00`)
      );
      form.setValues({ time: "9:00" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateValue, dateRangeValue]);

  return (
    <>
      <MantineProvider theme={searchTheme}>
        <Card
          shadow="md"
          radius="md"
          px={rem(20)}
          pt={rem(5)}
          pb={rem(20)}
          className={`overflow-visible min-w-full lg:min-w-[450px] max-w-full 
                      lg:max-w-[600px] w-full`}
          ref={cardRef}
        >
          <form onSubmit={form.onSubmit(handleSubmitData)}>
            <Radio.Group
              defaultValue={SEARCH_TYPE.ONE_WAY}
              mb="xs"
              {...form.getInputProps("searchType")}
            >
              <Group mt="xs">
                <Radio
                  value={SEARCH_TYPE.ONE_WAY}
                  label={t("common:search_type.one_way")}
                />
                <Radio
                  value={SEARCH_TYPE.ROUND_TRIP}
                  label={t("common:search_type.round_trip")}
                />
              </Group>
            </Radio.Group>
            <Flex
              className={`static lg:relative lg:bg-dark-4 bg-transparent rounded-md 
                          flex-col lg:flex-row`}
              gap={{ base: rem(6), md: rem(8) }}
            >
              <PopoverAlert
                opened={departureError?.isError || false}
                text={departureError?.errorMessage}
              >
                <Box className="md:max-md:w-3/6 w-full bg-dark-4 rounded-md">
                  <Text>{t("common:search.from")}</Text>
                  <LocationField
                    placeholder={t("common:search.from_placeholder")}
                    inputRef={departureClickListener}
                    name="depStationName"
                    routeData={routeData}
                    setRouteData={setRouteData}
                    cardRef={cardRef}
                    data={form.values.depStationName}
                    label={t("common:search.from")}
                    setValues={form.setValues}
                    value={form.values.depStationName}
                    onChange={(event) =>
                      form.setFieldValue("depStationName", event.target.value)
                    }
                    popularRoutes={popularRoutes}
                  />
                </Box>
              </PopoverAlert>
              <Button
                variant="subtle"
                radius={100}
                h={rem(35)}
                w={rem(35)}
                px={0}
                classNames={{
                  root: `shadow-lg left-auto lg:left-[calc(50%-25px)] right-[30px] 
                        lg:right-auto top-20 lg:top-5`,
                  label: "rotate-90 lg:rotate-0",
                }}
                pos="absolute"
                bg="white"
                onClick={() => swapDestinationValues()}
              >
                <IconArrowsExchange
                  height={24}
                  width={24}
                  className="text-orange-9"
                />
              </Button>
              <PopoverAlert
                opened={destinationError?.isError || false}
                text={destinationError?.errorMessage}
              >
                <Box className="md:max-md:w-3/6 w-full bg-dark-4 rounded-md">
                  <Text>{t("common:search.to")}</Text>
                  <LocationField
                    placeholder={t("common:search.to_placeholder")}
                    inputRef={destinationClickListener}
                    name="arvStationName"
                    routeData={routeData}
                    setRouteData={setRouteData}
                    cardRef={cardRef}
                    data={form.values.arvStationName}
                    label={t("common:search.to")}
                    setValues={form.setValues}
                    value={form.values.arvStationName}
                    onChange={(event) =>
                      form.setFieldValue("arvStationName", event.target.value)
                    }
                    popularRoutes={popularRoutes}
                  />
                </Box>
              </PopoverAlert>
            </Flex>
            <Flex
              my={{ base: rem(6), md: "sm" }}
              gap={{ base: rem(6), md: rem(8) }}
              className="flex-col lg:flex-row"
            >
              <Flex
                className="md:max-md:w-3/6 w-full"
                gap={{ base: rem(6), md: rem(8) }}
              >
                <Box className="bg-dark-4 rounded-md w-4/6">
                  <Text>{t("common:search.departure")}</Text>
                  <DatePickerInput
                    earliestDate={earliestDate}
                    isRoundTrip={isRoundTrip}
                    dateRangeValue={dateRangeValue}
                    dateValue={dateValue}
                    setDateValue={setDateValue}
                    setDateRangeValue={setDateRangeValue}
                  />
                </Box>
                <Box className="bg-dark-4 rounded-md w-2/6">
                  <Text>{t("common:search.time")}</Text>
                  <Box className="time-box px-0.5">
                    <Select
                      name="time"
                      data={timeOptions}
                      classNames={{
                        input: `bg-transparent border-0 w-[95%] text-dark-9 pr-2 
                                -mt-3 md:mt-0`,
                      }}
                      defaultValue="9:00"
                      withCheckIcon={false}
                      allowDeselect={false}
                      rightSection={<IconChevronDown width={16} height={16} />}
                      {...form.getInputProps("time")}
                    />
                  </Box>
                </Box>
              </Flex>
              <Box className="bg-dark-4 rounded-md md:max-md:w-3/6 w-full">
                <Text>{t("common:search.passengers")}</Text>
                <Flex gap="md" px="md">
                  <Box className="adult-box w-1/2">
                    <Flex align="center" className="-mt-3 md:mt-0">
                      <Text
                        size="md"
                        px={0}
                        pt={0}
                        fw={400}
                        w="-webkit-fill-available"
                        className="normal-case"
                      >
                        {t("common:search.adult")}
                      </Text>
                      <Select
                        name="adult"
                        data={Array.from({ length: 6 }, (_, index) =>
                          String(index + 1)
                        )}
                        classNames={{
                          input: "bg-transparent border-0 p-2 text-dark-9",
                        }}
                        defaultValue="1"
                        withCheckIcon={false}
                        allowDeselect={false}
                        rightSection={
                          <IconChevronDown width={16} height={16} />
                        }
                        {...form.getInputProps("adult")}
                      />
                    </Flex>
                  </Box>
                  <Box className="child-box w-1/2">
                    <Flex align="center" className="-mt-3 md:mt-0">
                      <Text
                        size="md"
                        px={0}
                        pt={0}
                        fw={400}
                        w="-webkit-fill-available"
                        className="normal-case"
                      >
                        {t("common:search.children")}
                      </Text>
                      <Select
                        name="child"
                        data={Array.from({ length: 6 }, (_, index) =>
                          String(index)
                        )}
                        classNames={{
                          input: "bg-transparent border-0 p-2 text-dark-9",
                        }}
                        defaultValue="0"
                        withCheckIcon={false}
                        allowDeselect={false}
                        rightSection={
                          <IconChevronDown width={16} height={16} />
                        }
                        {...form.getInputProps("child")}
                      />
                    </Flex>
                  </Box>
                </Flex>
              </Box>
            </Flex>
            <Box ta="right">
              <Anchor
                onClick={open}
                px={0}
                pt={0}
                size="sm"
                className="underline"
                c="gray.7"
                fw={400}
              >
                {t("common:search.age_classification")}
              </Anchor>
            </Box>
            <CTAButton
              text={t("common:search.cta")}
              mt="sm"
              type="submit"
              rightSection={<IconExternalLink strokeWidth={1.5} />}
            />
            <Text className="text-sm text-gray-7 !font-normal text-right !px-0">
              <Trans
                i18nKey="common:search.guide"
                components={{
                  url: (
                    <Link
                      locale={i18n.language}
                      className="underline text-custom-link"
                      href="/contact"
                      shallow={true}
                    />
                  ),
                }}
              />
            </Text>
          </form>
        </Card>
      </MantineProvider>
      <Modal
        opened={opened}
        onClose={close}
        size={rem(800)}
        centered
        title={isMobile ? t("common:search.age_classification") : ""}
        classNames={{
          header: `border-b lg:border-none border-solid border-l-0 border-r-0 border-t-0 
                  border-dark-5 block text-center py-5`,
          close: `absolute ${isMobile ? "right-2" : "left-2"} top-4`,
          inner: "p-2.5",
        }}
      >
        <Text mt="md" mb="xs" className="text-sm md:text-base">
          {t("common:search.table_description")}
        </Text>
        <TicketAgeClassification />
        <Text mt="md" mb="xs" className="text-sm md:text-base">
          <Trans
            i18nKey="common:search.action"
            components={{
              url: (
                <Link
                  locale={i18n.language}
                  className="underline text-custom-link underline-offset-4 decoration-1"
                  href="/guide/booking"
                  shallow={true}
                />
              ),
            }}
          />
        </Text>
      </Modal>
    </>
  );
}
