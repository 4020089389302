import { useDeviceQuery } from "@/utils";
import { Menu, TextInput, Button, Modal, rem, Box } from "@mantine/core";
import { DatePicker, DateValue, DatesRangeValue } from "@mantine/dates";
import { useDisclosure } from "@mantine/hooks";
import dayjs from "dayjs";
import "dayjs/locale/ko";
import "dayjs/locale/zh-cn";
import "dayjs/locale/zh-tw";
import "dayjs/locale/fr";
import "dayjs/locale/id";
import "dayjs/locale/es";
import { useTranslation } from "next-i18next";
import { useEffect, useState } from "react";

export function DatePickerInput({
  isRoundTrip,
  dateRangeValue,
  dateValue,
  setDateValue,
  setDateRangeValue,
  earliestDate,
}: {
  isRoundTrip: boolean;
  dateRangeValue: DatesRangeValue;
  dateValue: DateValue;
  setDateValue: (date: DateValue) => void;
  setDateRangeValue: (dates: DatesRangeValue) => void;
  earliestDate: DateValue | null;
}): JSX.Element {
  const { t, i18n } = useTranslation();
  const [isMobile] = useDeviceQuery();
  const [dropdownCalendarOpened, setDropdownCalendarOpened] =
    useState<boolean>(false);
  const [calendarData, setCalendarData] = useState<any>();
  const [
    popupCalendarOpened,
    { open: openPopupCalendar, close: closePopupCalendar },
  ] = useDisclosure(false);
  const valueFormat = () => {
    if (["fr", "id", "es"].includes(i18n.language)) {
      return "DD/MM/YYYY";
    } else if (["zh-cn", "zh-tw"].includes(i18n.language)) {
      return "YYYY/MM/DD";
    } else {
      return "MM/DD/YYYY";
    }
  };
  const dateDisplay = isRoundTrip
    ? `${dayjs(dateRangeValue[0]).format(valueFormat())} - ${
        dateRangeValue[1] ? dayjs(dateRangeValue[1]).format(valueFormat()) : ""
      }`
    : dayjs(dateValue).format(valueFormat());
  const minDate =
    earliestDate || dayjs(calendarData?.terminationQrDate).toDate();
  const maxDate =
    dayjs(calendarData?.advanceReserveEndQrDate).toDate() ||
    dayjs(new Date()).add(1, "month").toDate();

  /**
   * Get selectable range of dates based on data provided by NAVITIME.
   * If NAVITIME data is not available, default range will be 1 month from current date.
   */
  useEffect(() => {
    const fetchCalendarData = async () => {
      try {
        const response = await fetch(
          `${process.env.NEXT_PUBLIC_ORIGIN_URL}/static/cache/sales/jr/ticket-termination.json`
        );
        const jsonData = await response.json();
        setCalendarData(jsonData);
      } catch (error) {
        console.error("Error fetching JSON data:", error);
      }
    };

    fetchCalendarData();
  }, []);

  useEffect(() => {
    setDateValue(dayjs(calendarData?.terminationQrDate).toDate());
    setDateRangeValue([
      dayjs(calendarData?.terminationQrDate).toDate(),
      dayjs(calendarData?.terminationQrDate).add(1, "day").toDate(),
    ]);
  }, [calendarData?.terminationQrDate, setDateRangeValue, setDateValue]);

  const CalendarContent = () => {
    return (
      <>
        {isRoundTrip ? (
          <DatePicker
            type="range"
            allowSingleDateInRange
            value={
              dateRangeValue || [
                dayjs(calendarData?.terminationQrDate).toDate(),
                dayjs(calendarData?.terminationQrDate).add(1, "day").toDate(),
              ]
            }
            onChange={setDateRangeValue}
            minDate={minDate}
            maxDate={maxDate}
            locale={i18n.language}
            numberOfColumns={2}
            size={isMobile ? "md" : "sm"}
            classNames={{
              levelsGroup: "flex-col md:flex-row items-center",
            }}
            firstDayOfWeek={0}
          />
        ) : (
          <DatePicker
            allowDeselect={false}
            value={dateValue || dayjs(calendarData?.terminationQrDate).toDate()}
            onChange={setDateValue}
            minDate={minDate}
            maxDate={maxDate}
            locale={i18n.language}
            numberOfColumns={2}
            size={isMobile ? "md" : "sm"}
            classNames={{
              levelsGroup: "flex-col md:flex-row items-center",
            }}
            firstDayOfWeek={0}
          />
        )}
      </>
    );
  };

  const handleOpenCalendar = () => {
    if (isMobile) {
      openPopupCalendar();
    } else {
      setDropdownCalendarOpened(!dropdownCalendarOpened);
    }
  };

  const handleCloseCalendar = () => {
    if (!(isRoundTrip && !dateRangeValue[1])) {
      if (isMobile) {
        closePopupCalendar();
      } else {
        setDropdownCalendarOpened(!dropdownCalendarOpened);
      }
    }
  };

  return (
    <>
      <Menu
        closeOnClickOutside
        closeOnEscape
        classNames={{
          dropdown: "border-none drop-shadow-lg notranslate px-4",
        }}
        position="bottom-start"
        opened={dropdownCalendarOpened}
        onClose={handleCloseCalendar}
      >
        <Menu.Target>
          <TextInput
            type="text"
            autoComplete="off"
            placeholder={t("common:search.departure_placeholder")}
            classNames={{
              input: `bg-transparent border-0 ${
                isRoundTrip ? "text-xs" : "text-md"
              } -mt-3 md:mt-0 cursor-pointer`,
            }}
            onClick={handleOpenCalendar}
            value={dateDisplay}
            readOnly
          />
        </Menu.Target>

        <Menu.Dropdown visibleFrom="md">
          <CalendarContent />
          <Menu.Divider />
          <Box className="w-full text-right">
            <Button
              onClick={handleCloseCalendar}
              disabled={isRoundTrip && !dateRangeValue[1]}
            >
              {t("common:done")}
            </Button>
          </Box>
        </Menu.Dropdown>
      </Menu>

      <Modal
        opened={popupCalendarOpened}
        onClose={handleCloseCalendar}
        hiddenFrom="md"
        fullScreen
        title={dateDisplay}
        classNames={{
          close: "absolute right-3 top-3",
          header: `flex flex-col w-full px-0 text-center border border-solid 
                   border-t-0 border-l-0 border-r-0 border-dark-2`,
          title: "w-full",
        }}
      >
        <CalendarContent />
        <Button
          onClick={handleCloseCalendar}
          fullWidth
          size="lg"
          pos="sticky"
          bottom={rem(12)}
          mt="md"
          disabled={isRoundTrip && !dateRangeValue[1]}
        >
          {t("common:done")}
        </Button>
      </Modal>
    </>
  );
}
