import { getAssetUrl } from "@/utils";
import {
  Box,
  Flex,
  Stack,
  rem,
  Title,
  Text,
  Container,
  Group,
  SimpleGrid,
} from "@mantine/core";
import { useTranslation } from "next-i18next";
import { Search } from "../../Search";
import IconMobile from "../../Icons/icon-mobile.svg";
import IconTrain from "../../Icons/icon-train.svg";
import IconQrScan from "../../Icons/icon-qrscan.svg";
import Image from "next/image";
import { IconCircleCheckFilled } from "@tabler/icons-react";
import { Dispatch, RefObject, SetStateAction } from "react";

export function KeyVisual({
  setSearchRef,
  title,
  pcImg,
  spImg,
  area,
  subtitle,
}: {
  setSearchRef: Dispatch<SetStateAction<RefObject<HTMLDivElement> | null>>;
  title: string | React.ReactNode;
  pcImg: string;
  spImg: string;
  area?: string;
  subtitle?: string | false;
}): JSX.Element {
  const { t } = useTranslation();

  return (
    <>
      <Flex
        align="center"
        justify="space-between"
        className="bg-dark-4 flex-col lg:flex-row"
      >
        <Stack className="w-full lg:w-1/2 items-start lg:items-end px-3.75 lg:px-10 py-3 lg:py-8">
          <Stack
            align="flex-start"
            gap={rem(0)}
            w="100%"
            className="min-w-full lg:min-w-[450px] max-w-full lg:max-w-[600px] w-full"
          >
            <Box>
              {title}
              <Text size="md" c="dark.7" hiddenFrom="md">
                {subtitle || t("top:kv.subtitle.0")}
              </Text>
              <Text size="md" c="dark.7" visibleFrom="md">
                {subtitle || t("top:kv.subtitle", { joinArrays: " " })}
              </Text>
            </Box>
          </Stack>
          <Stack hiddenFrom="md" gap="xs">
            <Flex gap="xs">
              <Box h={16} w={16} className="text-blue-9">
                <IconCircleCheckFilled height={16} width={16} />
              </Box>
              <Stack gap={0}>
                <Text size="sm" c="blue.9" fw={700}>
                  {t("top:features.qr.title")}
                </Text>
              </Stack>
            </Flex>
            <Flex gap="xs">
              <Box h={16} w={16} className="text-blue-9">
                <IconCircleCheckFilled height={16} width={16} />
              </Box>
              <Stack gap={0}>
                <Text size="sm" c="blue.9" fw={700}>
                  {t("top:features.support.title")}
                </Text>
              </Stack>
            </Flex>
            <Flex gap="xs">
              <Box h={16} w={16} className="text-blue-9">
                <IconCircleCheckFilled height={16} width={16} />
              </Box>
              <Stack gap={0}>
                <Text size="sm" c="blue.9" fw={700}>
                  {t("top:features.booking.title")}
                </Text>
              </Stack>
            </Flex>
          </Stack>

          <Search setSearchRef={setSearchRef} area={area} />
        </Stack>
        <Box pos="relative" className="w-1/2 h-[535px]" visibleFrom="md">
          <Image
            alt="japan-shinkansen"
            src={getAssetUrl(pcImg)}
            height={505}
            width={698}
            priority
            className="object-cover h-full w-full"
          />
        </Box>
        <Box pos="relative" className="w-full h-[230px]" hiddenFrom="md">
          <Image
            alt="japan-shinkansen"
            src={getAssetUrl(spImg)}
            height={390}
            width={230}
            priority
            className="object-cover h-full w-full"
          />
        </Box>
      </Flex>
      <Box
        className="border-b border-solid border-dark-4 bg-custom-rose-white"
        visibleFrom="md"
      >
        <Container py={rem(40)} size="xl">
          <SimpleGrid cols={{ base: 1, md: 3 }}>
            <Group>
              <IconMobile />
              <Stack gap={0} w="80%">
                <Title order={5} c="blue.9" fw={700}>
                  {t("top:features.booking.title")}
                </Title>
                <Text size="md" c="dark.8">
                  {t("top:features.booking.subtitle")}
                </Text>
              </Stack>
            </Group>
            <Group>
              <IconTrain />
              <Stack gap={0} w="80%">
                <Title order={5} c="blue.9" fw={700}>
                  {t("top:features.tickets.title")}
                </Title>
                <Text size="md" c="dark.8">
                  {t("top:features.tickets.subtitle")}
                </Text>
              </Stack>
            </Group>
            <Group>
              <IconQrScan />
              <Stack gap={0} w="80%">
                <Title order={5} c="blue.9" fw={700}>
                  {t("top:features.qr.title")}
                </Title>
                <Text size="md" c="dark.8">
                  {t("top:features.qr.subtitle")}
                </Text>
              </Stack>
            </Group>
          </SimpleGrid>
        </Container>
      </Box>
    </>
  );
}
