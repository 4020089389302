import { Button, ButtonProps, rem } from "@mantine/core";

interface Props extends ButtonProps {
  text: string;
  type?: "button" | "submit" | "reset" | undefined;
  onClick?: () => void;
}

export function CTAButton({
  text,
  type,
  onClick,
  ...rest
}: Props): JSX.Element {
  return (
    <Button
      fullWidth
      size="md"
      h={rem(60)}
      classNames={{
        root: `uppercase bg-gradient-to-r from-gradient-orange-100
               to-gradient-red-90 hover:bg-transparent custom-shine-animation`,
        section: "absolute right-5",
      }}
      fw={700}
      type={type}
      onClick={onClick}
      {...rest}
    >
      {text}
      <i></i>
    </Button>
  );
}
